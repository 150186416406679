import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '../components/Container'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTheme } from '@mui/material'

interface FaqGroupItemProps {
    items: Array<{
        title: string
        subtitle: string
    }>
}

const FaqGroupItem = ({ items }: FaqGroupItemProps): JSX.Element => {
    const theme = useTheme()
    return (
        <Box>
            <Box>
                {items.map((item, i) => (
                    <Box
                        component={Accordion}
                        key={i}
                        padding={1}
                        marginBottom={2}
                        borderRadius={`${theme.spacing(1)} !important`}
                        sx={{
                            '&::before': {
                                display: 'none'
                            }
                        }}
                    >
                        <Box
                            component={AccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={`panel1a-header--${i}`}
                        >
                            <Typography fontWeight={600}>
                                {item.title}
                            </Typography>
                        </Box>
                        <AccordionDetails>
                            <Typography
                                color="text.secondary"
                                textAlign={'left'}
                            >
                                {item.subtitle}
                            </Typography>
                        </AccordionDetails>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

const FAQ = (): JSX.Element => {
    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="white"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            FAQ
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box marginBottom={4}>
                    <Box marginBottom={2}>
                        <FaqGroupItem
                            items={[
                                {
                                    title: 'What countries does wΞlock support?',
                                    subtitle:
                                        'wΞlock is currently available only in Japan. We are working hard to support other countries.'
                                },
                                {
                                    title: 'What blockchain does wΞlock support?',
                                    subtitle:
                                        'wΞlock supports Ethereum Mainnet and Polygon Matic Network. We are planning to support additional chains and networks.'
                                },
                                {
                                    title: 'How much does it cost to operate wΞlock?',
                                    subtitle:
                                        '$0. wΞlock just requires digital signature. No gas is required.'
                                },
                                {
                                    title: 'Is wΞlock a trustless system?',
                                    subtitle:
                                        'Not at the moment.' +
                                        'Currently, wΞlock is centralized and has several points of trust. In particular, our backend server, APIs like Infura and smart lock devices.' +
                                        'We are currently focusing on enabling authentication by self sovereign IDs, ENS, NFTs and other smart contracts, and experiences with Web3 in the real world.' +
                                        'However, in the long term, we want to realize authentication and authorization of physical devices with a trustless and decentralized system. For more details, please see the w3a.io.'
                                },
                                {
                                    title: 'What smart lock devices does wΞlock support?',
                                    subtitle:
                                        'wΞlock adds a Web3 authentication feature to existing smart lock devices. Test smart lock devices we deliver to EAP participants are made by third-party companies. We currently supports Sesame 4 (Available only in Japan).'
                                },
                                {
                                    title: 'What’s EAP Ticket NFT?',
                                    subtitle:
                                        'EAP Ticket NFT is needed to participate in the wΞlock Early Access Program.' +
                                        'By burning EAP Ticket NFT,  EAP Owners Club NFT will automatically be minted to your address. You will be able to access your test device application form when your EAP Owners Club NFT is minted.'
                                },
                                {
                                    title: "What's EAP Owners Club NFT?",
                                    subtitle:
                                        'EAP Owners Club NFT is an access right of wΞlock.' +
                                        'EAP Owners Club NFT will automatically be minted to your address when your EAP Ticket NFTis burned.' +
                                        'Do not transfer EAP Owners Club NFT, otherwise you will lose access to wΞlock.'
                                }
                            ]}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default FAQ
