import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import PublishIcon from '@mui/icons-material/Publish'
import Container from '../components/Container'

const mock = [
    {
        title: 'Purchase/mint wΞlock EAP Ticket NFT',
        color: '#FFC050',
        subtitle:
            'Mint on this page or you can purchase at OpenSea for second hands if sold out.',
        icon: (
            <svg
                height={24}
                width={24}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                />
            </svg>
        )
    },
    {
        title: 'Burn EAP Ticket NFT at admin website',
        subtitle:
            'The wΞlock EAP Ticket NFT can then be burned in the wΞlock admin website.',
        icon: <LocalFireDepartmentIcon />,
        color: 'red'
    },
    {
        title: 'Apply for a smart lock device',
        subtitle:
            'In return for burning, you can get access to the preview version of wΞlock (EAP Owners Club NFT) and a free smart lock device for testing.',
        icon: <PublishIcon />,
        color: 'blue'
    }
]

const HowToStart = (): JSX.Element => {
    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="white"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            How to participate?
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    {mock.map((item, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box width={1} height={1}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                >
                                    <Box
                                        component={Avatar}
                                        width={60}
                                        height={60}
                                        marginBottom={2}
                                        bgcolor={'white'}
                                        color={item.color}
                                    >
                                        {item.icon}
                                    </Box>
                                    <Typography
                                        variant={'h6'}
                                        gutterBottom
                                        sx={{ fontWeight: 500 }}
                                        align={'center'}
                                        color={'white'}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        color={'white'}
                                        align={'center'}
                                    >
                                        {item.subtitle}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    )
}

export default HowToStart
