import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack'
import * as Router from './routes'
import './modules/firebase'
import { getAnalytics } from 'firebase/analytics'
import { CssBaseline } from '@mui/material'

getAnalytics()

const App = () => {
    return (
        <>
            <CssBaseline />
            <SnackbarProvider
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                variant={'info'}
            >
                <Router.Router />
            </SnackbarProvider>
        </>

    )
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
