import * as React from 'react'
import { styled } from '@mui/material'

type Props = {
    children: React.ReactNode
}

export const Component = (props: Props) => {
    return (
        <Body>
            {props.children}
        </Body>
    )
}

const Body = styled('div')({
    background: '-webkit-linear-gradient(left, #579FFF, #3048FF)'
})
