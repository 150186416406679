import * as React from 'react'
import * as MainLayout from '../layouts/Main'
import { Grid, CardMedia, Card } from '@mui/material'
import Logo from '../assets/w3lock_EAP.png'
import ResponsiveAppBar from '../components/AppBar'
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'
import Container from '../components/Container'
import Description from '../components/Description'
import HowToStart from '../components/HowToStart'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type Props = {
    onClickMint: () => void
}

export const Component = (props: Props) => {
    return (
        <MainLayout.Component>
            <ResponsiveAppBar onClickMint={props.onClickMint} />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <Card elevation={0}>
                        <CardMedia
                            component="img"
                            alt="logo"
                            src={Logo}
                            sx={{
                                marginTop: 2,
                                'imageRendering': '-webkit-optimize-contrast',
                                position: 'absolute',
                                transform: 'translateX(-50%)'
                            }}
                        />
                    </Card>
                </Grid>
                <Grid
                    item
                    textAlign={'center'}
                    sx={{
                        marginTop: { xs: 25, sm: 35, md: 50, lg: 72, xl: 132 }
                    }}
                >
                    <Description />
                </Grid>
                <Grid item textAlign={'center'}>
                    <Box marginBottom={2} marginX={1}>
                        <Typography
                            variant="h4"
                            color="white"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            Price: 0.42 ETH
                        </Typography>
                    </Box>
                </Grid>
                <Grid item textAlign={'center'}>
                    <HowToStart />
                </Grid>
                <Grid item textAlign={'center'}>
                    <FAQ />
                </Grid>
                <Grid>
                    <Container>
                        <Footer />
                    </Container>
                </Grid>
            </Grid>
        </MainLayout.Component>
    )
}
