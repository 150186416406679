import * as React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import * as Mint from '../pages/Mint'
import * as NotFound from '../pages/NotFound'

export const Router = (): React.ReactElement => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Mint.Component />} />
                <Route path="*" element={<NotFound.Component />} />
            </Routes>
        </BrowserRouter>
    )
}
