import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Button } from '@mui/material'

type Props = {
    onClickMint: () => void
}

const ResponsiveAppBar = (props: Props) => {
    return (
        <AppBar
            position="fixed"
            sx={{
                background: '-webkit-linear-gradient(left, #579FFF, #3048FF)'
            }}
            elevation={0}
        >
            <Toolbar>
                <Box
                    display="flex"
                    flexGrow={1}
                    sx={{ alignItems: 'center' }}
                ></Box>
                <Button
                    sx={{
                        paddingX: 4,
                        fontSize: 18,
                        background: 'white',
                        color: 'black',
                        ':hover': {
                            background: 'lightgray',
                            color: 'black'
                        }
                    }}
                    onClick={props.onClickMint}
                >
                    Mint
                </Button>
            </Toolbar>
        </AppBar>
    )
}
export default ResponsiveAppBar
