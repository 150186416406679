import * as React from 'react'
import * as MainLayout from '../layouts/Main'
import { CardMedia, Grid, Typography } from '@mui/material'
import Logo from '../assets/logo.png'

type Props = {}

export const Component = (_props: Props) => {
    return (
        <MainLayout.Component>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Grid item>
                    <CardMedia
                        component="img"
                        alt="logo"
                        src={Logo}
                        sx={{
                            width: '60vw',
                            mb: 8,
                            'imageRendering': '-webkit-optimize-contrast'
                        }}
                    />
                </Grid>

                <Grid item>
                    <Typography variant="h5" component="div" gutterBottom>
                        Not Found
                    </Typography>
                </Grid>
            </Grid>
        </MainLayout.Component>
    )
}
