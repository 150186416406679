import * as React from 'react'
import * as MintTemplate from '../templates/Mint'

import { serializeError } from 'eth-rpc-errors'

import { useSnackbar } from 'notistack'
import { providers } from 'ethers'
import {isMobileSafari} from 'react-device-detect'
import detectEthereumProvider from '@metamask/detect-provider'

import { W3lockEAPTicket__factory } from '../modules/typechain'

const ETHERSCAN_TX_URL = 'https://etherscan.io/tx/'

export const Component = () => {
    const { enqueueSnackbar } = useSnackbar()

    const onClickMint = React.useCallback(async () => {
        try {
            const provider = (await detectEthereumProvider({ silent: true, timeout: 0 })) as any

            if (window.ethereum === undefined || !provider) {
                if (isMobileSafari) {
                    // eslint-disable-next-line
                    location.href = `dapp://${location.hostname}${location.pathname}${location.search}`
                    return
                }
                if (window.confirm('Open in the MetaMask app?')) {
                    // eslint-disable-next-line
                    location.href = `dapp://${location.hostname}${location.pathname}${location.search}`
                    return
                } else {
                    enqueueSnackbar('MetaMask Should Be Installed', {
                        variant: 'error'
                    })
                    return
                }
            }

            await provider.request({ method: 'eth_requestAccounts' })

            const ethersProvider = new providers.Web3Provider(provider)
            const signer = ethersProvider.getSigner()

            const contract = W3lockEAPTicket__factory.connect(
                '0xea40f1992d9416ad4529816edd3d75e65de34ee9',
                signer
            )
            const fee = await contract.fee()
            const tx = await contract.mint({ value: fee })

            enqueueSnackbar(
                <span>
                    <a href={`${ETHERSCAN_TX_URL}${tx.hash}`} target={'_blank'} rel="noreferrer">
                        Transaction
                    </a>{' '}
                    has been sent!
                </span>,
                {
                    autoHideDuration: 10000
                }
            )
        } catch (e) {
            const errorMessage = serializeError(e)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            enqueueSnackbar(errorMessage.data.originalError.reason, {
                variant: 'error'
            })
        }
    }, [enqueueSnackbar])

    return <MintTemplate.Component onClickMint={onClickMint} />
}
