import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Container from '../components/Container'
import { CardMedia } from '@mui/material'

const Description = (): JSX.Element => {
    return (
        <Container>
            <Box>
                <Box marginBottom={4}>
                    <Box marginBottom={2}>
                        <Typography
                            variant="h4"
                            color="white"
                            align={'center'}
                            gutterBottom
                            sx={{
                                fontWeight: 700
                            }}
                        >
                            wΞlock is a Web3 smart lock system
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="white"
                            sx={{ fontWeight: 400 }}
                            align={'center'}
                        >
                            that enables authentication by NFTs, ENS, and other
                            Smart Contracts.
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="white"
                            sx={{ fontWeight: 400 }}
                            align={'center'}
                        >
                            For example, you can easily create a place that only
                            those with a particular NFT or ENS can enter.
                        </Typography>
                    </Box>
                </Box>
                <Box marginBottom={4}>
                    <Box>
                        <CardMedia
                            component="iframe"
                            title="w3lock"
                            src="https://www.youtube.com/embed/qENaHXQ6GqA"
                            sx={{
                                width: '100%',
                                height: {
                                    xs: '30vh',
                                    sm: '40vh',
                                    md: '60vh'
                                },
                                border: 0,
                                margin: 'auto'
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default Description
