import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Grid, IconButton } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as BookSVG } from '../assets/book.svg'
import { ReactComponent as TwitterSVG } from '../assets/twitter-square.svg'
import { ReactComponent as DiscordSVG } from '../assets/discord.svg'
import { ReactComponent as EthereumSVG } from '../assets/ethereum.svg'
import { ReactComponent as OpenSeaSVG} from '../assets/opensea.svg'

function Book(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <BookSVG/>
        </SvgIcon>
    );
}

function Twitter(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <TwitterSVG/>
        </SvgIcon>
    );
}

function Discord(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <DiscordSVG/>
        </SvgIcon>
    );
}

function Ethereum(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <EthereumSVG/>
        </SvgIcon>
    );
}

function OpenSea(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
           <OpenSeaSVG/>
        </SvgIcon>
    );
}

const Footer = (): JSX.Element => {
    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    })

    return (
        <Box>
            <Box marginBottom={2}>
                <Typography
                    variant="h4"
                    color="white"
                    align={'center'}
                    gutterBottom
                    sx={{
                        fontWeight: 700
                    }}
                >
                    More Details
                </Typography>
            </Box>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    flexDirection={{ xs: 'row', sm: 'row' }}
                    alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                    justifyContent={'center'}
                    marginTop={1}
                >
                    <IconButton
                        sx={{
                            width: 'auto',
                            height: '100%'}}
                        onClick={() => {
                        window.open(
                            'https://opensea.io/collection/w3lock-eap-ticket',
                            '_blank'
                        )
                    }}
                    >
                        <OpenSea/>
                    </IconButton>
                    <IconButton onClick={() => {
                        window.open(
                            'https://docs.google.com/document/d/19qukuz8Ax95niKK1mdO9Z21TSiPgaiVHMoPoA1NHX6Q/edit',
                            '_blank'
                        )
                    }}>
                        <Book/>
                    </IconButton>
                    <IconButton onClick={() => {
                        window.open(
                            'https://discord.gg/65DgxbWc7z',
                            '_blank'
                        )
                    }}>
                        <Discord/>
                    </IconButton>
                    <IconButton onClick={() => {
                        window.open('https://twitter.com/w3lock', '_blank')
                    }}>
                        <Twitter/>
                    </IconButton>

                    <IconButton onClick={() => {
                        window.open(
                            'https://etherscan.io/address/0xea40f1992d9416ad4529816edd3d75e65de34ee9#code',
                            '_blank'
                        )
                    }}>
                        <Ethereum/>
                    </IconButton>
                </Box>
            </Grid>
        </Box>
    )
}

export default Footer
